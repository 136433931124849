import { configureStore } from '@reduxjs/toolkit'
// User
import  AuthReducer  from './actions/auth'
import  HomeReducer  from './actions/home'

// Admin
import  BatchReducer  from './actions/admin/batch'
import  CourseReducer  from './actions/admin/course'
import  SessionReducer  from './actions/admin/session'
import  SubjectReducer  from './actions/admin/subject'
import  SettingReducer  from './actions/admin/setting'
import  AdmissionReducer  from './actions/admin/admission'
import  FieldReducer  from './actions/admin/fields'
import StudentReducer from "./actions/admin/student"
import EnrollmentReducer from "./actions/admin/enrollment"
import MaterialReducer from "./actions/admin/material"

import  StateReducer  from './actions/state'
import  ZoneReducer  from './actions/zone'
import  DistrictReducer  from './actions/district'
import  CentersReducer  from './actions/center'
import  UserReducer  from './actions/admin/user'
import RoleReducer from './actions/admin/role'
import ModulesReducer from './actions/modules'
import PlansReducer from './actions/plan'
import PermissionReducer from './actions/admin/permission'
import taskType from './actions/admin/taskType'
import task from './actions/admin/task'
import signInReducer from './actions/signIn'
import ForgetPasswordReducer from './actions/forgetpassword'
import classRoom from './actions/admin/classRoom'
import PeriodReducer from './actions/admin/period'
import DashboardReducer from './actions/admin/dashboard'
import CustomRecordReducer  from './actions/admin/customrecord'

export default configureStore({
  reducer: {
    //User
    auth: AuthReducer,
    home: HomeReducer,
    
    //Admin
    batch: BatchReducer,
    course: CourseReducer,
    session: SessionReducer,
    subject: SubjectReducer,
    setting: SettingReducer,
    admission: AdmissionReducer,
    field: FieldReducer,
    student: StudentReducer,
    enrollment: EnrollmentReducer,
    period: PeriodReducer,
    material: MaterialReducer,
    dashboard: DashboardReducer,
    customrecord: CustomRecordReducer,

    state: StateReducer,
    zone: ZoneReducer,
    district: DistrictReducer,
    centers: CentersReducer,
    signIn: signInReducer,
    user: UserReducer,
    role: RoleReducer,
    // auth: AuthReducer,
    forgetPassword: ForgetPasswordReducer,
    module: ModulesReducer,
    plan : PlansReducer,
    permissions: PermissionReducer, // Added this line
    taskType: taskType, 
    task: task,
    classRoom: classRoom, 

    
  },
});